import { FC, PropsWithChildren } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { Direction } from "apps/website/maps/Flex.map";
import cx from "apps/website/utils/misc/cx";

import TaggedSummaryGroup, {
  ITaggedSummaryGroupProps,
} from "./TaggedSummaryGroup";

export interface ITaggedSummaryProps {
  groups: ITaggedSummaryGroupProps[];
  theme?: Theme;
  direction?: Direction;
  className?: string;
  wrap?: boolean;
}

const TaggedSummary: FC<PropsWithChildren<ITaggedSummaryProps>> = ({
  groups,
  children,
  theme = "brand",
  direction = "column",
  className,
  wrap = true,
}) => (
  <div
    className={cx("p-4", themeRootClassMap[theme], className, { "lg:grid lg:grid-cols-12 lg:gap-4": direction === "row" })}
    data-component={ TaggedSummary.name }
  >
    { groups.map((group) => (
      <div
        key={ group.title }
        className={ `${direction === "row"
          ? "lg:col-span-4 lg:flex lg:flex-col lg:justify-start lg:items-center"
          : ""
        }` }
      >
        <TaggedSummaryGroup key={ group.title } { ...group } wrap={wrap} />
      </div>
    )) }
    <div data-children>{ children }</div>
  </div>
);

export default TaggedSummary;
