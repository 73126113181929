import { FC, memo } from "react";

import ListItem from "../../base/ListItem/ListItem";
import Tag from "../../base/Tag/Tag";
import Text from "../../base/Text/Text";
import { AvailableTag } from "../../base/Text/Text.map";

export interface ITaggedSummaryGroupProps {
  title: string;
  titleTag?: AvailableTag;
  tags: string[];
  className?: string;
  wrap?: boolean;
}

const TaggedSummaryGroup: FC<ITaggedSummaryGroupProps> = ({
  title,
  titleTag = "h3",
  tags,
  className,
  wrap = true,
}) => (
  <div
    className={ `my-1 ${wrap ? null : "overflow-scroll"} ${className}` }
    data-component={ TaggedSummaryGroup.name }
  >
    <Text
      display="default"
      tag={ titleTag }
      size={ {
        default: "2xs",
        md: "default",
      } }
      className="first-letter:capitalize"
    >
      { title }
    </Text>
    <ul
      className={ `flex flex-row gap-2 my-2 ${wrap ? "flex-wrap" : null}`}
    >
      { tags.filter((filteredTag) => filteredTag.trim() !== "").map((tag, index) => (
        <ListItem
          key={ `${index}-${tag}` }
        >
          <Tag
            size={ "cluster-default" }
            theme={ "dark" }
          >
            { tag }
          </Tag>
        </ListItem>
      )) }
    </ul>
  </div>
);

export default memo(TaggedSummaryGroup);
