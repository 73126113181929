import { IconSvg, Size } from "../../base/Icon/Icon.map";

import styles from "./Carousel.module.css";

export const overflowClassMap = {
  default: styles.overflowHidden,
  visible: styles.overflowVisible,
  dim: styles.overflowDim,
};

export const perspectiveClassMap = {
  default: styles.perspectiveDefault,
  skewed: styles.perspectiveSkewed,
};

export const spaceBetweenMap = {
  default: 16,
  none: 0,
};

export const navigationStyleMap: Record<string, INavigationStyle> = {
  default: {
    leftIcon: "arrowLeft",
    rightIcon: "arrowRight",
    leftIconClassName: "bottom-0 left-0",
    rightIconClassName: "bottom-0 right-0",
    iconSize: "default",
  },
  secondary: {
    leftIcon: "chevronLeft",
    rightIcon: "chevronRight",
    iconWrapperClassName: "rounded-full bg-white border-2 border-solid p-2",
    leftIconClassName: "bottom-1/2 translate-y-1/2 left-4",
    rightIconClassName: "bottom-1/2 translate-y-1/2 right-4",
    iconSize: "medium",
  },
  tertiary: {
    leftIcon: "chevronLeft",
    rightIcon: "chevronRight",
    iconWrapperClassName: "hidden",
    leftIconClassName: "hidden",
    rightIconClassName: "hidden",
    iconSize: "medium",
  },
};

export interface INavigationStyle {
  leftIcon: IconSvg;
  rightIcon: IconSvg;
  iconWrapperClassName?: string;
  leftIconClassName: string;
  rightIconClassName: string;
  iconSize: Size;
}

export type Overflow = keyof typeof overflowClassMap;
export type Perspective = keyof typeof perspectiveClassMap;
export type SpaceBetween = keyof typeof spaceBetweenMap;
export type NavigationStyle = keyof typeof navigationStyleMap;
